import Api from '../../services/Api'
import { isBrowser } from '../../utils/common'
import { myAccountURL } from '../../ApiEndpoints'

const useAccountIframe = () => {
  async function getAccountUrl() {
    const response = await Api.get(myAccountURL)
    return response.data
  }

  const setIframe = async () => {
    if (isBrowser) {
      const gatsbyElement = document.getElementById('___gatsby')

      const iframeElement = document.createElement('iframe')
      const accountData = await getAccountUrl()

      if (accountData && accountData?.url) {
        iframeElement.src = accountData['url']
        iframeElement.id = 'account-iframe'
        iframeElement.style.display = 'none'

        iframeElement.addEventListener('load', () => {})

        gatsbyElement.appendChild(iframeElement)
      }
    }
  }

  const removeIframe = () => {
    if (isBrowser) {
      const existingIframeElement = document.getElementById('account-iframe')

      if (existingIframeElement) {
        document.body.removeChild(existingIframeElement)
      }
    }
  }

  const getIframe = () => {
    if (isBrowser) {
      const existingIframeElement = document.getElementById('account-iframe')

      if (existingIframeElement) {
        const url = existingIframeElement.src
        return url
      }
    }
  }

  return {
    setIframe,
    removeIframe,
    getIframe,
  }
}

export default useAccountIframe
