import { navigate } from 'gatsby'
import { useState } from 'react'
import Api from '../../services/Api'
import { formatCreateUserData } from '../../utils/user'
import useToastify from '../ui/useToastify'
import {
  bcApiURL,
  customerAddcustomerNewAccountURL,
  customerNewAccountURL,
} from '../../ApiEndpoints'

function useCreateAccount() {
  const [isLoading, setLoading] = useState(false)
  const [state, setState] = useState({
    isTokenValid: false,
    isChecked: false,
  })

  const [stateEmail, setStateEmail] = useState('')
  const { toastMessage } = useToastify()

  const sendNewAccountMail = async (email_id) => {
    try {
      setLoading(true)
      const data = {
        email: email_id.toLowerCase(),
      }
      const res = await sendMail(customerNewAccountURL, data)
      let result = false
      if (res?.status === 200) {
        toastMessage('success', 'Mail sent successfully')
        result = true
      } else if (res?.status === 409) {
        toastMessage('error', 'Account already exists.')
      } else {
        toastMessage('error', 'Bad request.')
      }
      setLoading(false)
      return result
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const validateToken = async (token) => {
    try {
      setLoading(true)
      const params = {
        token: token,
      }
      const res = await checkTokenValid(customerNewAccountURL, params)
      if (res?.status === 200) {
        setState({ ...state, isTokenValid: true })
        setStateEmail(res?.data?.is_valid?.email)
      } else if (res?.status === 403) {
        setState({ ...state, isTokenValid: false })
        toastMessage('error', 'Token is invalid')
      } else {
        setState({ ...state, isTokenValid: false })
        toastMessage('error', 'Bad request.')
      }
      setLoading(false)
      setState({ ...state, isChecked: true })
      return res
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const createNewCustomerAccount = async (data) => {
    try {
      setLoading(true)
      const res = await createNewAccount(customerAddcustomerNewAccountURL, data)
      let result = {}
      if (res?.status === 200) {
        toastMessage('success', 'Customer created successfully')
        result = res?.data?.data
      } else if (res?.status === 422) {
        toastMessage('error', 'Create customers failed.')
      } else if (res?.status === 413) {
        toastMessage('error', 'The request payload is too large.')
      } else if (res?.status === 409) {
        toastMessage('error', 'Account already exists.')
      } else {
        toastMessage('error', 'Bad request.')
      }
      setLoading(false)
      return result
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const createCustomerAccount = async (data) => {
    try {
      const url = `${bcApiURL}?type=create_account`
      const body = {
        url: 'v3/customers',
        query_params: {},
        method: 'post',
        body: formatCreateUserData(data),
      }
      setLoading(true)
      const res = await createAccount(url, body)

      if (res.status === 422) {
        toastMessage('error', 'This email id is already in use.')
      }

      if (res.status === 200) {
        navigate('/account-success')
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return {
    createCustomerAccount,
    sendNewAccountMail,
    validateToken,
    createNewCustomerAccount,
    isLoading,
    state,
    stateEmail,
  }
}

async function createAccount(url, body) {
  const response = await Api.post(url, body)
  return response?.data ? response.data : response
}

async function sendMail(url, data) {
  const response = await Api.post(url, data)
  if (response?.status === 200) {
    return response
  }
  return response.response
}

async function checkTokenValid(url, params) {
  const response = await Api.get(url, params, null)
  if (response?.status === 200) {
    return response
  }
  return response.response
}

async function createNewAccount(url, data) {
  const response = await Api.post(url, data)
  if (response?.status === 200) {
    return response
  }
  return response.response
}

export default useCreateAccount
