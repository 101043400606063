import * as Yup from 'yup'
import {
  emailRegExp,
  emailRegMsg,
  requiredEmailMsg,
  requiredMsg,
} from '../utils/form'
import { createOption } from '../utils/components'
import { passwordRegExp, passwordRegMsg } from '../utils/form'

export const formatCreateUserData = (data) => {
  const res = {
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    phone: data.phone,
    authentication: {
      new_password: data.password,
    },
  }

  return [res]
}

export const COUNTRY_OPTIONS = [createOption('US', 'United States')]

export const sign_up_schema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegExp, emailRegMsg)
    .email(requiredEmailMsg())
    .required(requiredEmailMsg('Email Address')),
  password: Yup.string().min(7).required(requiredMsg('password')),
  confirm_password: Yup.string()
    .label('confirm password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required(requiredMsg('confirm password')),
  country: Yup.string().required(requiredMsg('country')),
  first_name: Yup.string().required(requiredMsg('first name')),
  last_name: Yup.string().required(requiredMsg('last name')),
  address_line_1: Yup.string().required(requiredMsg('address')),
  suburb_city: Yup.string().required(requiredMsg('suburb/city')),
  state_province: Yup.string().required(requiredMsg('state/province')),
  zip_postcode: Yup.string().required(requiredMsg('zip code')),
  phone: Yup.string().required(requiredMsg('phone number')),
})

export const initialValues = {
  email: '',
  password: '',
  confirm_password: '',
  country: '',
  first_name: '',
  last_name: '',
  address_line_1: '',
  address_line_2: '',
  company_name: '',
  suburb_city: '',
  state_province: '',
  zip_postcode: '',
  phone: '',
}

export const login_validation_schema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegExp, emailRegMsg)
    .email(requiredEmailMsg())
    .required(requiredEmailMsg('Email Address')),
  password: Yup.string().min(7).required(requiredMsg('Password')),
})

export const login_form_initial_values = {
  email: '',
  password: '',
}

export const new_password_initial_values = {
  new_password: '',
  confirm_password: '',
}

export const new_password_schema = Yup.object().shape({
  new_password: Yup.string()
    .matches(passwordRegExp, passwordRegMsg)
    .required('Password is required'),
  confirm_password: Yup.string()
    .label('confirm password')
    .required('Please confirm your Password')
    .oneOf([Yup.ref('new_password')], 'Passwords must match'),
})
