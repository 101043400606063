import React, { useState, useEffect } from "react";
import Layout from "../layout/Layout";
import useCreateAccount from "../hooks/customer/useCreateAccount";
import Button from "../components/form/button/Button";
import { createOption } from "../utils/components";
import { useLocation } from "@reach/router";
import { useForm } from "react-hook-form";
import useToastify from "../hooks/ui/useToastify";
import { useDispatch } from "react-redux";
import useAccountIframe from "../hooks/customer/useAccountIframe";
import { login } from "../features/userSlice";
import { navigate } from "gatsby";
import { passwordRegExp, passwordRegMsg } from "../utils/form";
import Loader from "../components/form/Loader";
import useGetAllStates from "../hooks/useGetAllStates";
import { staticPage } from "../../config/staticPage";

const COUNTRY_OPTIONS = [
  { value: "", label: "Select a country" },
  { value: "US", label: "United States" },
];

const STATE_OPTIONS = {
  US: [],
};

const BUSINESS_TYPE_OPTIONS = [
  createOption("Online Vape Store", "Online Vape Store"),
  createOption("Online CBD Store", "Online CBD Store"),
  createOption("Brick and Mortar Vape Store", "Brick and Mortar Vape Store"),
  createOption("Brick and Mortar CBD Store", "Brick and Mortar CBD Store"),
  createOption(
    "Brick and Mortar Tobacco / Glass / Vape Store",
    "Brick and Mortar Tobacco / Glass / Vape Store"
  ),
  createOption("Convenience Store", "Convenience Store"),
  createOption("Gas Station", "Gas Station"),
  createOption("Distributor", "Distributor"),
  createOption("Cash-Carry / Jobber", "Cash-Carry / Jobber"),
];

const ABOUT_US_OPTIONS = [
  createOption("Trade Show", "Trade Show"),
  createOption(
    "Champs Atlantic City May 2023",
    "Champs Atlantic City May 2023"
  ),
  createOption(
    "Alt Pro Expo Columbia May 2023",
    "Alt Pro Expo Columbia May 2023"
  ),
  createOption("Email", "Email"),
  createOption("Google Search", "Google Search"),
  createOption("E-Juice Magazine", "E-Juice Magazine"),
  createOption("Facebook", "Facebook"),
  createOption("Instagram", "Instagram"),
  createOption("Twitter", "Twitter"),
  createOption("Midwest Goods Catalogue", "Midwest Goods Catalogue "),
  createOption("Midwest Goods Sales Team", "Midwest Goods Sales Team"),
  createOption("CBD To Store", "CBD To Store"),
  createOption("Other", "Other"),
];

const CreateNewAccountPage = () => {
  const {
    state,
    stateEmail,
    isLoading,
    createNewCustomerAccount,
    validateToken,
  } = useCreateAccount();

  const location = useLocation();
  const { toastMessage } = useToastify();
  const dispatch = useDispatch();
  const { setIframe } = useAccountIframe();

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [stateOptions, setStateOptions] = useState([]);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const urlToken = searchParams.get("token");
  const [isValid, setIsValid] = useState(false);
  const allstate = useGetAllStates();

  //fill state array dynamically
  if (!allstate.isLoading) {
    STATE_OPTIONS["US"] = allstate.STATE_OPTIONS;
  }

  const validateCustomer = (values) => {
    dispatch(login({ values: values, toastMessage: toastMessage })).then(
      async (res) => {
        // after successfully logged in...
        if (res?.payload) {
          if (res.payload?.status === 200) {
            await setIframe();
            navigate("/orders");
          }
        }
      }
    );
  };

  // const urlToken =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhSUpwYndBWk5nTnltdjZRUE9RRFRTOUsiLCJpYXQiOjE3MTc1MTUyMjMsImV4cCI6MTcxNzUxODgyMywianRpIjoiNjRmZDJkY2ZjZGY5NDQzYzgyMjI2NmIwNzU2NTQ3ZjkiLCJzdG9yZV9pZCI6IjYzNzY2OWRkNzRkZTlhZGE3NDUzMmI3NyIsImVtYWlsIjoieGV4aW5pMzU1NkBqemV4cG9ydC5jb20ifQ.P_fv9_-Gt_dXPqq3S3WuLfANc8wiIFaCXqyXssEv5nk";

  useEffect(() => {
    if (!state.isChecked) {
      const checkValidity = async () => {
        //check if token is availible with url
        if (urlToken) {
          const res = await validateToken(urlToken);
          if (res?.status === 200) {
            setIsValid(true);
          } else {
            setIsValid(false);
          }
        } else {
          setIsValid(false);
        }
      };
      checkValidity();
    }
  }, [state.isChecked]);

  useEffect(() => {}, [stateEmail]);

  let defaultValues = {
    password: "",
    confirm_password: "",
    ein_number: "",
    business_type: "",
    website_url: "",
    about_us: "",
    first_name: "",
    last_name: "",
    company_name: "",
    country: "",
    zip_postcode: "",
    suburb_city: "",
    state_province: "",
    address_line_1: "",
    address_line_2: "",
    phone: "",
    business_hours: "",
    dock_type: "",
    forklift_type: "",
    lift_gate_service_type: "",
  };

  const handleCountryChange = (e) => {
    const countryValue = e.target.value;
    setSelectedCountry(countryValue);

    // Update state options based on selected country
    if (STATE_OPTIONS.hasOwnProperty(countryValue)) {
      setStateOptions(STATE_OPTIONS[countryValue]);
    } else {
      setStateOptions([]);
    }

    // Clear the selected state
    setSelectedState("");
    if (countryValue !== "") {
      errors.country = "";
      setCountryError(false);
    } else {
      setCountryError(true);
      setStateError(true);
    }
  };

  const handleStateChange = (e) => {
    const stateValue = e.target.value;
    setSelectedState(stateValue);

    if (stateValue !== "") {
      setStateError(false);
      errors.state_province = "";
    } else {
      setStateError(true);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (values) => {
    const newObj = {
      email: stateEmail,
      first_name: values["first_name"],
      last_name: values["last_name"],
      company: values["company_name"],
      phone: values["phone"],
      customer_group_id: 73,
      addresses: [
        {
          first_name: values["first_name"],
          last_name: values["last_name"],
          address1: values["address_line_1"],
          address2: values["address_line_2"],
          city: values["suburb_city"],
          state_or_province: values["state_province"],
          postal_code: values["zip_postcode"],
          country_code: values["country"],
          phone: values["phone"],
          form_fields: [
            {
              name: "Business Hours For Delivery",
              value: values["business_hours"],
            },
            {
              name: "Do You have a dock?",
              value: values["dock_type"],
            },
            {
              name: "Do you have forklift to unload merchandise from truck?",
              value: values["forklift_type"],
            },
            {
              name: "Do you Require Lift Gate Service?",
              value: values["lift_gate_service_type"],
            },
          ],
        },
      ],
      authentication: {
        force_password_reset: false,
        new_password: values["password"],
      },
      form_fields: [
        {
          name: "Type of Business",
          value: values["business_type"],
        },
        {
          name: "How did you hear about us?",
          value: values["about_us"],
        },
        {
          name: "Your Website URL If Applicable",
          value: values["website_url"],
        },
        {
          name: "Ein # ( 9 digit Number located on IRS Letter or Tax Forms )",
          value: values["ein_number"],
        },
        {
          name: "Do You have a dock?",
          value: values["dock_type"],
        },
        {
          name: "Do you have forklift to unload merchandise from truck?",
          value: values["forklift_type"],
        },
        {
          name: "Do you Require Lift Gate Service?",
          value: values["lift_gate_service_type"],
        },
      ],
    };
    if (stateEmail !== "") {
      const finalObject = [newObj];
      const res = await createNewCustomerAccount(finalObject);
      if (res && Object.keys(res).length !== 0) {
        validateCustomer(res);
      }
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-heading-section">
          <div className="container">
            <h1 className="page-title">New Account</h1>
          </div>
        </div>
      </div>

      <div className="container">
        {isLoading === true ? (
          <Loader />
        ) : isValid === false ? (
          <p className="text-center">
            Your token is expired or invalid. Please regenerate it.
          </p>
        ) : (
          <form
            className="form create-account-form form-mid"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-row form-row--half">
              <div className="form-field">
                <label className="form-label" htmlFor="email">
                  Email Address
                  <small>REQUIRED</small>
                </label>
                <input
                  id="email"
                  type="email"
                  className={`form-input`}
                  value={stateEmail}
                  disabled
                />
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="password">
                  Password
                  <small>REQUIRED</small>
                </label>
                <input
                  id="password"
                  type="password"
                  {...register("password", {
                    required: "Password is required",
                    pattern: {
                      value: passwordRegExp,
                      message: passwordRegMsg(),
                    },
                  })}
                  className={`form-input ${errors.password ? "error" : ""}`}
                />
                {errors.password && (
                  <div className="form-error-message">
                    {errors.password.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="confirm_password">
                  Confirm Password
                  <small>REQUIRED</small>
                </label>
                <input
                  id="confirm_password"
                  type="password"
                  {...register("confirm_password", {
                    required: "Confirm password is required",
                    validate: (value) =>
                      value === watch("password") || "Passwords do not match",
                  })}
                  className={`form-input ${
                    errors.confirm_password ? "error" : ""
                  }`}
                />
                {errors.confirm_password && (
                  <div className="form-error-message">
                    {errors.confirm_password.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="ein_number">
                  Ein # ( 9 digit Number located on IRS Letter or Tax Forms ) No
                  Dash Please
                  <small>REQUIRED</small>
                </label>
                <input
                  id="ein_number"
                  type="number"
                  {...register("ein_number", {
                    required: "Ein number is required",
                    minLength: {
                      value: 9,
                      message: "Ein number must be exactly 9 digits",
                    },
                    maxLength: {
                      value: 9,
                      message: "Ein number must be exactly 9 digits",
                    },
                  })}
                  className={`form-input ${errors.ein_number ? "error" : ""}`}
                />
                {errors.ein_number && (
                  <div className="form-error-message">
                    {errors.ein_number.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="select_business_type">
                  Type of Business
                  <small>REQUIRED</small>
                </label>
                <select
                  id="select_business_type"
                  className={`form-select ${
                    errors.business_type ? "error" : ""
                  }`}
                  aria-label="Select example"
                  {...register("business_type", {
                    required: "Business type is required",
                  })}
                >
                  <option value="">Select one</option>
                  {BUSINESS_TYPE_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.business_type && (
                  <div className="form-error-message">
                    {errors.business_type.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="website_url">
                  Your Website URL If Applicable
                </label>
                <input
                  id="website_url"
                  type="text"
                  {...register("website_url")}
                  className={`form-input`}
                  maxLength={50}
                />
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="select_here_about_us">
                  How did you hear about us?
                  <small>REQUIRED</small>
                </label>
                <select
                  id="select_here_about_us"
                  className={`form-select ${errors.about_us ? "error" : ""}`}
                  aria-label="Select example"
                  {...register("about_us", {
                    required: "How did you hear about us? is required",
                  })}
                >
                  <option value="">Select one</option>
                  {ABOUT_US_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.about_us && (
                  <div className="form-error-message">
                    {errors.about_us.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="first_name">
                  First Name
                  <small>REQUIRED</small>
                </label>
                <input
                  id="first_name"
                  type="text"
                  {...register("first_name", {
                    required: "First name is required",
                  })}
                  className={`form-input ${errors.first_name ? "error" : ""}`}
                  maxLength={50}
                />
                {errors.first_name && (
                  <div className="form-error-message">
                    {errors.first_name.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="last_name">
                  Last Name
                  <small>REQUIRED</small>
                </label>
                <input
                  id="last_name"
                  type="text"
                  {...register("last_name", {
                    required: "Last name is required",
                  })}
                  className={`form-input ${errors.last_name ? "error" : ""}`}
                  maxLength={50}
                />
                {errors.last_name && (
                  <div className="form-error-message">
                    {errors.last_name.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="company_name">
                  Business Name
                  <small>REQUIRED</small>
                </label>
                <input
                  id="company_name"
                  type="text"
                  {...register("company_name", {
                    required: "Business name is required",
                  })}
                  className={`form-input ${errors.company_name ? "error" : ""}`}
                  maxLength={50}
                />
                {errors.company_name && (
                  <div className="form-error-message">
                    {errors.company_name.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="country">
                  Country
                  <small>REQUIRED</small>
                </label>
                <select
                  id="country"
                  className={`form-select ${
                    errors.country || countryError ? "error" : ""
                  }`}
                  {...register("country", { required: "Country is required" })}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  {COUNTRY_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {(errors.country && (
                  <div className="form-error-message">
                    {errors.country.message}
                  </div>
                )) ||
                  (countryError && (
                    <div className="form-error-message">
                      Country is required
                    </div>
                  ))}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="zip_postcode">
                  Zip/Postcode
                  <small>REQUIRED</small>
                </label>
                <input
                  id="zip_postcode"
                  type="text"
                  {...register("zip_postcode", {
                    required: "Zip/Postcode is required",
                  })}
                  className={`form-input ${errors.zip_postcode ? "error" : ""}`}
                  maxLength={6}
                />
                {errors.zip_postcode && (
                  <div className="form-error-message">
                    {errors.zip_postcode.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="state_province">
                  State/Province
                  <small>REQUIRED</small>
                </label>
                <select
                  id="state_province"
                  {...register("state_province", {
                    required: "State/Province is required",
                  })}
                  className={`form-select ${
                    errors.state_province || stateError ? "error" : ""
                  }`}
                  value={selectedState}
                  onChange={handleStateChange}
                >
                  {stateOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {(errors.state_province && (
                  <div className="form-error-message">
                    {errors.state_province.message}
                  </div>
                )) ||
                  (stateError && (
                    <div className="form-error-message">
                      State/Province is required
                    </div>
                  ))}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="suburb_city">
                  Suburb/City
                  <small>REQUIRED</small>
                </label>
                <input
                  id="suburb_city"
                  type="text"
                  {...register("suburb_city", {
                    required: "Suburb/City is required",
                  })}
                  className={`form-input ${errors.suburb_city ? "error" : ""}`}
                  maxLength={50}
                />
                {errors.suburb_city && (
                  <div className="form-error-message">
                    {errors.suburb_city.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="address_line_1">
                  Business Address Line 1 (Street Address Only)
                  <small>REQUIRED</small>
                </label>
                <input
                  id="address_line_1"
                  type="text"
                  {...register("address_line_1", {
                    required: "Business Address Line 1 is required",
                  })}
                  className={`form-input ${
                    errors.address_line_1 ? "error" : ""
                  }`}
                  maxLength={50}
                />
                {errors.address_line_1 && (
                  <div className="form-error-message">
                    {errors.address_line_1.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="address_line_2">
                  Business Address Line 2 (STE APT ETC)
                </label>
                <input
                  id="address_line_2"
                  type="text"
                  {...register("address_line_2")}
                  className={`form-input`}
                  maxLength={50}
                />
              </div>

              <div className="form-field">
                <label className="form-label">
                  Phone Number
                  <small>REQUIRED</small>
                </label>
                <input
                  id="phone"
                  type="number"
                  {...register("phone", {
                    required: "Phone number is required",
                    minLength: {
                      value: 10,
                      message: "Phone number must be at least 10 digits long.",
                    },
                    maxLength: {
                      value: 14,
                      message:
                        "Phone number should not exceed 14 digits in length.",
                    },
                  })}
                  className={`form-input ${errors.phone ? "error" : ""}`}
                />
                {errors.phone && (
                  <div className="form-error-message">
                    {errors.phone.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="business_hours">
                  Business Hours For Delivery
                  <small>REQUIRED</small>
                </label>
                <input
                  id="business_hours"
                  type="text"
                  {...register("business_hours", {
                    required: "Business hours for delivery is required",
                  })}
                  className={`form-input ${
                    errors.business_hours ? "error" : ""
                  }`}
                  maxLength={50}
                />
                {errors.business_hours && (
                  <div className="form-error-message">
                    {errors.business_hours.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="dock_type">
                  Do You have a dock?
                </label>
                <select
                  id="select_dock_type"
                  className={`form-select ${
                    errors.business_type ? "error" : ""
                  }`}
                  aria-label="Select example"
                  {...register("dock_type", {
                    required: "Dock type is required",
                  })}
                >
                  <option value="">Pick one</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  <option value="Not Applicable">Not Applicable</option>
                </select>
                {errors.dock_type && (
                  <div className="form-error-message">
                    {errors.dock_type.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="forklift_type">
                  Do you have forklift to unload merchandise from truck?
                </label>
                <select
                  id="select_forklift_type"
                  className={`form-select ${
                    errors.business_type ? "error" : ""
                  }`}
                  aria-label="Select example"
                  {...register("forklift_type", {
                    required: "Forklift type is required",
                  })}
                >
                  <option value="">Pick one</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  <option value="Not Applicable">Not Applicable</option>
                </select>
                {errors.forklift_type && (
                  <div className="form-error-message">
                    {errors.forklift_type.message}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="lift_gate_service_type">
                  Do you Require Lift Gate Service?
                </label>
                <select
                  id="select_forklift_type"
                  className={`form-select ${
                    errors.business_type ? "error" : ""
                  }`}
                  aria-label="Select example"
                  {...register("lift_gate_service_type", {
                    required: "Forklift type is required",
                  })}
                >
                  <option value="">Pick one</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  <option value="Not Applicable">Not Applicable</option>
                </select>
                {errors.lift_gate_service_type && (
                  <div className="form-error-message">
                    {errors.lift_gate_service_type.message}
                  </div>
                )}
              </div>

              {/* Rest of the form fields with similar pattern */}
            </div>

            <div className="form-actions text-center">
              <Button type="submit" className="button" disabled={!isValid}>
                Create Account
              </Button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "create-new-account") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

CreateNewAccountPage.Layout = Layout
export default CreateNewAccountPage;
